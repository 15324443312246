<template>
  <div class="catalog-content">
    <div class="container">
      <div class="catalog-links">
        <template v-for="(item, index) in catalog" :key="index">
          <router-link
            v-if="item.count > 0"
            :href="item.menu.id"
            :to="{
                name: 'catalog-category',
                params: { category: item.menu.id},
              }"
            class="catalog-link catalog-link--small">
            <span class="catalog-link__text">{{ item.menu.name }}</span>
          </router-link>

          <a
            v-else-if="isExternal"
            :href="redirects[index].to"
            :class="[ historyCategory === item.menu.code ? 'active' : '' ]"
            class="catalog-link catalog-link--small">
            <span class="catalog-link__text">{{ item.menu.name }}</span>
          </a>

          <router-link
            v-else
            :href="item.menu.code"
            :to="{
                name: 'catalog-list',
                params: { category: item.menu.code, page: 1 },
              }"
            :class="[ historyCategory === item.menu.code ? 'active' : '' ]"
            class="catalog-link catalog-link--small">
            <span class="catalog-link__text">{{ item.menu.name }}</span>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";

export default {
  beforeRouteEnter(to, from, next) {
    store
      .dispatch("CATALOG_FETCH_DATA", {
        'category': to.params.category,
      })
      .then(() => {
        next();
      });
  },

  beforeRouteUpdate(to, from, next) {
    store
      .dispatch("CATALOG_FETCH_DATA", {
        'category': to.params.category,
      })
      .then(() => {
        next();
      });
  },

  computed: {
    catalog() {
      return this.$store.state.catalog.catalog;
    },

    redirects() {
      if (Object.keys(this.$store.state.redirects).length > 0) {
        // console.log(this.$route.params.category)
        let  redirects = this.$store.state.redirects

        redirects = Object.values(redirects).sort((a, b) => (a.sort > b.sort) ? 1 : -1);
        return redirects;
      } else {
        return [];
      }
    },

    isExternal() {
      if(this.$route.params.category == "3679" && this.$store.state.redirects) {
        return true
      } else return false
    },

    historyCategory() {
      return localStorage.getItem("subcategory");
    }
  },
};
</script>
